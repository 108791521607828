@font-face {
  font-family: Poppins;
  src: url(./assets//fonts/Poppins-Medium.ttf);
}

* {
  font-family: 'Poppins';
}

.mapping-table [class*='MUIDataTableSelectCell-root'].MuiTableCell-head {
  height: 100px;
  border: unset;
}

.mapping-table div.MuiToolbar-root :has(div.custom-toolbar) {
  display: flex;
  justify-content: right;
  align-items: center;
  flex-direction: row-reverse;
}

.mapping-table td:has(div.hasError) {
  background-color: #ea0c31;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
}
